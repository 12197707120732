
// Blockquote
// ––––––––––––––––––––––––––––––––––––––––––––––––––

blockquote {
	border-left: .3rem solid $color-secondary-light;
	margin-left: 0;
	margin-right: 0;
	padding: 1rem 1.5rem;

	*:last-child {
		margin-bottom: 0; } }
