
// List
// ––––––––––––––––––––––––––––––––––––––––––––––––––

dl,
ol,
ul {
	list-style: none;
	margin-top: 0;
	padding-left: 0;

	dl,
	ol,
	ul {
		font-size: 90%;
		margin: 1.5rem 0 1.5rem 3.0rem; } }

ul {
	list-style: disc inside; }

ul li {
    padding-left: 2rem;
    text-indent: -2rem; }

ol {
  list-style: none;
  counter-reset: list-counter; }

ol li {
  counter-increment: list-counter;
  margin-left: 3rem;
  position: relative; }

ol li::before {
  content: counter(list-counter) ". ";
  font-weight: 700;
  position: absolute;
  left: -3rem;
  display: block;
  width: 2.5rem;
  text-align: right; }
