
// Table
// ––––––––––––––––––––––––––––––––––––––––––––––––––

table {
	border-spacing: 0;
	width: 100%; }

td,
th {
	border-bottom: .1rem solid $color-rule;
	padding: 0.7rem 1rem;
	text-align: left;
	line-height: 1.3;

	&:first-child {
		padding-left: 0; }

	&:last-child {
		padding-right: 0; }

	&.figure {
		text-align: right;
		&:last-child {
			padding-right: 0.3rem; } } }

tr:last-child td {
	border-bottom: none; }

th {
	vertical-align: bottom;
	line-height: 1;
	text-transform: uppercase;
	font-size: .8em; }

td {
	vertical-align: top; }
