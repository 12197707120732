$progressBarHeight: 0.5rem;

.progress-bar {
  position: relative;
  width: 50%;
  height: $progressBarHeight;
  overflow-x: hidden;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.progress-bar__line {
  position: absolute;
  opacity: 0.4;
  background: $color-primary;
  width: 100%;
  height: $progressBarHeight;
}

.progress-bar__subline {
  position: absolute;
  background: $color-primary;
  height: $progressBarHeight;
}
.progress-bar__inc {
  animation: progress-bar-increase 2s infinite;
}
.progress-bar__dec {
  animation: progress-bar-decrease 2s 0.5s infinite;
}

@keyframes progress-bar-increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 50%;
  }
}
@keyframes progress-bar-decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}

.progress-spinner {
  border: 0.25rem solid #f3f3f3; /* Light grey */
  border-top: 0.25rem solid $color-primary; /* Blue */
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.four-oh-four {
  background: url(/gr/404.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 66%;
  overflow: visible;
  height: 0;
}