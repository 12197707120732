
// Button
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: none;
	background-color: $color-success;
	border: .1rem solid $color-success;
	border-radius: .4rem;
	color: white;
	cursor: pointer;
	display: inline-block;
	font-size: 1.4rem;
	font-weight: 300;
	height: 3.8rem;
	letter-spacing: .1rem;
	line-height: 3.8rem;
	padding: 0 3.0rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;

	&:hover {
		background-color: $color-success-light;
		border-color: $color-success-light;
		color: white;
		outline: 0; }

	&:not(.button--inline):active {
		border-color: $color-success;
		background-color: $color-success;
		position: relative;
		top: 0.1rem; }

	&[disabled] {
		cursor: default;
		opacity: .5;

		&:focus,
		&:hover {
			background-color: $color-primary;
			border-color: $color-primary; } }

	&.button-outline {
		background-color: transparent;
		color: $color-primary;

		&:focus,
		&:hover {
			background-color: transparent;
			border-color: $color-success;
			color: $color-success; }

		&[disabled] {

			&:focus,
			&:hover {
				border-color: inherit;
				color: $color-primary; } } }

	&.button-clear {
		background-color: transparent;
		border-color: transparent;
		color: $color-primary;

		&:focus,
		&:hover {
			background-color: transparent;
			border-color: transparent;
			color: $color-success; }

		&[disabled] {

			&:focus,
			&:hover {
				color: $color-primary; } } } }
