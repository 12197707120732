
// Utility
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// Clear a float with .clearfix
.clearfix {

	&:after {
		clear: both;
		content: ' '; // The space content is one way to avoid an Opera bug.
		display: table; } }

// Float either direction
.float-left {
	float: left; }

.float-right {
	float: right; }

.nobr {
	white-space: nowrap; }

.link--underline {
	text-decoration: underline !important; }

.user-select {
	user-select: all; }

.error {
	color: $color-cancel; }

.cursor-pass-through {
	pointer-events: none; }

