:export {
  mobileBreakpoint: 900px;
  colorBody: #3a393a;
  colorError: #dd0606;
  colorPanel: #fafafa;
  colorErrorLight: rgba(221, 6, 6, 0.8);
  colorErrorPanel: rgba(221, 6, 6, 0.1);
  colorNeutral1: #ccc;
  colorNeutral2: #aaa;
  colorNeutral3: #999;
  colorNeutral4: #505050;
  colorPanelDark: #003d73;
  colorPrimary: #0074d9;
  colorPrimaryLight: #40a6ff;
  colorPrimaryLighter: #d9edff;
  colorPrimaryPanel: rgba(0, 116, 217, 0.1);
  colorRule: #a5a5a5;
  colorSecondary: #ee2323;
  colorSecondaryLight: #f58282;
  colorSuccess: #2b802b;
  colorSuccessLight: rgba(43, 128, 43, 0.8);
  colorSuccessLighter: rgba(43, 128, 43, 0.5);
  colorSuccessPanel: rgba(43, 128, 43, 0.1);
  colorWap: #fd9d02;
  colorCancel: #dd0606; }

*,
*:after,
*:before {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  margin: 0;
  scroll-behavior: smooth; }

body {
  color: #3a393a;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.6em;
  font-weight: 300;
  letter-spacing: .01em;
  line-height: 1.6;
  margin: 0; }

blockquote {
  border-left: 0.3rem solid #f58282;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem; }
  blockquote *:last-child {
    margin-bottom: 0; }

.button,
button,
.prop-card__button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: none;
  background-color: #2b802b;
  border: 0.1rem solid #2b802b;
  border-radius: .4rem;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 300;
  height: 3.8rem;
  letter-spacing: .1rem;
  line-height: 3.8rem;
  padding: 0 3.0rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap; }
  .button:hover,
  button:hover,
  .prop-card__button:hover,
  input[type='button']:hover,
  input[type='reset']:hover,
  input[type='submit']:hover {
    background-color: rgba(43, 128, 43, 0.8);
    border-color: rgba(43, 128, 43, 0.8);
    color: white;
    outline: 0; }
  .button:not(.button--inline):active,
  button:not(.button--inline):active,
  .prop-card__button:not(.button--inline):active,
  input[type='button']:not(.button--inline):active,
  input[type='reset']:not(.button--inline):active,
  input[type='submit']:not(.button--inline):active {
    border-color: #2b802b;
    background-color: #2b802b;
    position: relative;
    top: 0.1rem; }
  .button[disabled],
  button[disabled],
  .prop-card__button[disabled],
  input[type='button'][disabled],
  input[type='reset'][disabled],
  input[type='submit'][disabled] {
    cursor: default;
    opacity: .5; }
    .button[disabled]:focus, .button[disabled]:hover,
    button[disabled]:focus,
    .prop-card__button[disabled]:focus,
    button[disabled]:hover,
    .prop-card__button[disabled]:hover,
    input[type='button'][disabled]:focus,
    input[type='button'][disabled]:hover,
    input[type='reset'][disabled]:focus,
    input[type='reset'][disabled]:hover,
    input[type='submit'][disabled]:focus,
    input[type='submit'][disabled]:hover {
      background-color: #0074d9;
      border-color: #0074d9; }
  .button.button-outline,
  button.button-outline,
  .button-outline.prop-card__button,
  input[type='button'].button-outline,
  input[type='reset'].button-outline,
  input[type='submit'].button-outline {
    background-color: transparent;
    color: #0074d9; }
    .button.button-outline:focus, .button.button-outline:hover,
    button.button-outline:focus,
    .button-outline.prop-card__button:focus,
    button.button-outline:hover,
    .button-outline.prop-card__button:hover,
    input[type='button'].button-outline:focus,
    input[type='button'].button-outline:hover,
    input[type='reset'].button-outline:focus,
    input[type='reset'].button-outline:hover,
    input[type='submit'].button-outline:focus,
    input[type='submit'].button-outline:hover {
      background-color: transparent;
      border-color: #2b802b;
      color: #2b802b; }
    .button.button-outline[disabled]:focus, .button.button-outline[disabled]:hover,
    button.button-outline[disabled]:focus,
    .button-outline.prop-card__button[disabled]:focus,
    button.button-outline[disabled]:hover,
    .button-outline.prop-card__button[disabled]:hover,
    input[type='button'].button-outline[disabled]:focus,
    input[type='button'].button-outline[disabled]:hover,
    input[type='reset'].button-outline[disabled]:focus,
    input[type='reset'].button-outline[disabled]:hover,
    input[type='submit'].button-outline[disabled]:focus,
    input[type='submit'].button-outline[disabled]:hover {
      border-color: inherit;
      color: #0074d9; }
  .button.button-clear,
  button.button-clear,
  .button-clear.prop-card__button,
  input[type='button'].button-clear,
  input[type='reset'].button-clear,
  input[type='submit'].button-clear {
    background-color: transparent;
    border-color: transparent;
    color: #0074d9; }
    .button.button-clear:focus, .button.button-clear:hover,
    button.button-clear:focus,
    .button-clear.prop-card__button:focus,
    button.button-clear:hover,
    .button-clear.prop-card__button:hover,
    input[type='button'].button-clear:focus,
    input[type='button'].button-clear:hover,
    input[type='reset'].button-clear:focus,
    input[type='reset'].button-clear:hover,
    input[type='submit'].button-clear:focus,
    input[type='submit'].button-clear:hover {
      background-color: transparent;
      border-color: transparent;
      color: #2b802b; }
    .button.button-clear[disabled]:focus, .button.button-clear[disabled]:hover,
    button.button-clear[disabled]:focus,
    .button-clear.prop-card__button[disabled]:focus,
    button.button-clear[disabled]:hover,
    .button-clear.prop-card__button[disabled]:hover,
    input[type='button'].button-clear[disabled]:focus,
    input[type='button'].button-clear[disabled]:hover,
    input[type='reset'].button-clear[disabled]:focus,
    input[type='reset'].button-clear[disabled]:hover,
    input[type='submit'].button-clear[disabled]:focus,
    input[type='submit'].button-clear[disabled]:hover {
      color: #0074d9; }

hr {
  border: 0;
  border-top: 0.1rem solid #a5a5a5;
  margin: 3.0rem 0; }

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
  appearance: none;
  background-color: transparent;
  border: 0.1rem solid #a5a5a5;
  border-radius: .4rem;
  box-shadow: none;
  box-sizing: inherit;
  height: 3.8rem;
  padding: .6rem 1.0rem;
  width: 100%;
  font-size: 1.6rem;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  input[type='email']:focus,
  input[type='number']:focus,
  input[type='password']:focus,
  input[type='search']:focus,
  input[type='tel']:focus,
  input[type='text']:focus,
  input[type='url']:focus,
  textarea:focus,
  select:focus {
    border-color: #0074d9;
    outline: 0; }

select {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#d1d1d1" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>') center right no-repeat;
  padding-right: 3.0rem; }
  select:focus {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#9b4dca" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>'); }

textarea {
  min-height: 6.5rem; }

label,
legend {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: .5rem; }

fieldset {
  border-width: 0;
  padding: 0; }

input[type='checkbox'],
input[type='radio'] {
  display: inline; }

.label-inline {
  display: inline-block;
  font-weight: normal;
  margin-left: .5rem; }

a {
  color: #0074d9;
  text-decoration: underline;
  touch-action: manipulation; }
  a:focus, a:hover {
    color: #0074d9; }

dl,
ol,
ul {
  list-style: none;
  margin-top: 0;
  padding-left: 0; }
  dl dl,
  dl ol,
  dl ul,
  ol dl,
  ol ol,
  ol ul,
  ul dl,
  ul ol,
  ul ul {
    font-size: 90%;
    margin: 1.5rem 0 1.5rem 3.0rem; }

ul {
  list-style: disc inside; }

ul li {
  padding-left: 2rem;
  text-indent: -2rem; }

ol {
  list-style: none;
  counter-reset: list-counter; }

ol li {
  counter-increment: list-counter;
  margin-left: 3rem;
  position: relative; }

ol li::before {
  content: counter(list-counter) ". ";
  font-weight: 700;
  position: absolute;
  left: -3rem;
  display: block;
  width: 2.5rem;
  text-align: right; }

.button,
button,
.prop-card__button,
dd,
dt,
li {
  margin-bottom: 1.0rem; }

fieldset,
input,
select,
textarea {
  margin-bottom: 1.5rem; }

blockquote,
dl,
figure,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 1.6rem; }

table {
  border-spacing: 0;
  width: 100%; }

td,
th,
.option__header .option__odds,
.option__header .option__bet-input,
.option__header .option__main {
  border-bottom: 0.1rem solid #a5a5a5;
  padding: 0.7rem 1rem;
  text-align: left;
  line-height: 1.3; }
  td:first-child,
  th:first-child,
  .option__header .option__odds:first-child,
  .option__header .option__bet-input:first-child,
  .option__header .option__main:first-child {
    padding-left: 0; }
  td:last-child,
  th:last-child,
  .option__header .option__odds:last-child,
  .option__header .option__bet-input:last-child,
  .option__header .option__main:last-child {
    padding-right: 0; }
  td.figure,
  th.figure,
  .option__header .figure.option__odds,
  .option__header .figure.option__bet-input,
  .option__header .figure.option__main {
    text-align: right; }
    td.figure:last-child,
    th.figure:last-child,
    .option__header .figure.option__odds:last-child,
    .option__header .figure.option__bet-input:last-child,
    .option__header .figure.option__main:last-child {
      padding-right: 0.3rem; }

tr:last-child td {
  border-bottom: none; }


th,
.option__header .option__odds,
.option__header .option__bet-input,
.option__header .option__main {
  vertical-align: bottom;
  line-height: 1;
  text-transform: uppercase;
  font-size: .8em; }

td {
  vertical-align: top; }

img {
  max-width: 100%; }

.clearfix:after {
  clear: both;
  content: ' ';
  display: table; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.nobr {
  white-space: nowrap; }

.link--underline {
  text-decoration: underline !important; }

.user-select {
  user-select: all; }

.error {
  color: #dd0606; }

.cursor-pass-through {
  pointer-events: none; }

#root {
  position: relative; }

#root > main {
  max-width: 100rem;
  position: relative;
  left: 250px;
  width: calc(100vw - 250px);
  min-height: calc(100vh - 1.6rem - 2rem); }
  #root > main > section {
    padding: calc(4rem * 0.5 + 4.6rem) 4rem 4rem;
    width: 100%;
    position: relative;
    top: 0;
    left: 0; }
    #root > main > section.fade-enter, #root > main > section.fade-enter-active, #root > main > section.fade-exit, #root > main > section.fade-exit-active {
      position: absolute; }
    #root > main > section.fade-enter-done {
      position: relative; }

#root > .alert + main > section {
  padding: 4rem; }

.sidebar {
  width: 250px;
  height: 100vh;
  overflow-x: visible;
  overflow-y: scroll;
  position: fixed; }

.alert {
  background: #003d73;
  color: white;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem; }
  .alert--primary-color {
    background: #0074d9; }
  .alert .button--inline,
  .alert a {
    color: white;
    text-decoration: underline; }
  .alert input {
    background: white; }
  .alert > *:last-child {
    margin-bottom: 0; }

#root > .alert + main {
  top: 0; }

.alert--full-width {
  padding: 8rem 4rem 2rem;
  position: relative;
  left: 250px;
  width: calc(100% - 250px); }

.banner {
  align-items: center;
  background-color: #0074d9;
  display: flex;
  height: 4.6rem;
  padding: 0.25rem 2rem 0.5rem;
  position: fixed;
  width: 100%;
  z-index: 200; }
  .banner .banner__h1 {
    color: white;
    display: inline-block;
    flex-grow: 1;
    font-size: 4rem;
    font-style: italic;
    font-weight: 800;
    letter-spacing: -0.15rem;
    line-height: 1;
    margin: 0;
    text-shadow: 0.25rem 0 0.5rem rgba(0, 0, 0, 0.2);
    transition: font-size 0.25s; }
  .banner a {
    color: white;
    text-decoration: none; }
  .banner.banner--homepage a {
    cursor: default; }
  .banner:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3Cpattern%20id%3D%22a%22%20patternUnits%3D%22userSpaceOnUse%22%20width%3D%225%22%20height%3D%225%22%20patternTransform%3D%22rotate(45)%22%3E%3Cpath%20stroke%3D%22%23000%22%20d%3D%22M1%200v5%22%2F%3E%3C%2Fpattern%3E%3C%2Fdefs%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22url(%23a)%22%2F%3E%3C%2Fsvg%3E");
    content: "";
    opacity: 0.2;
    z-index: 5; }
  .banner * {
    z-index: 10; }

.banner__logo {
  height: 2rem;
  width: 4.5rem;
  display: inline-block;
  position: relative;
  top: -0.8rem;
  transition: all 0.25s;
  margin-right: 0.5rem; }
  .banner__logo svg {
    float: left;
    filter: drop-shadow(0 0.3rem 0.2rem rgba(0, 0, 0, 0.4)); }

.banner__tagline {
  color: white;
  display: inline-block;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 300;
  margin-left: 0.5rem;
  text-shadow: none;
  transition: all 0.25s;
  letter-spacing: 0; }

.banner--scrolled {
  height: 3.2rem; }
  .banner--scrolled.banner {
    padding-bottom: 0.1rem; }
  .banner--scrolled .banner__h1 {
    font-size: 2rem;
    letter-spacing: -0.075rem;
    text-shadow: 0.1rem 0 0.25rem rgba(0, 0, 0, 0.1);
    line-height: 1; }
  .banner--scrolled .banner__logo {
    height: 0;
    width: 0;
    opacity: 0; }
  .banner--scrolled .banner__tagline {
    font-size: 0.8rem;
    margin-top: 0.2rem; }

.banner--dev .banner__h1--env {
  color: yellow;
  font-weight: 400; }

.banner--staging .banner__h1--env {
  color: red;
  font-weight: 400; }

.logo__group {
  isolation: isolate; }

.logo__gradient {
  opacity: 0.6;
  mix-blend-mode: multiply;
  fill: url(#a); }

.banner__menu-toggle.button--inline,
.banner__menu-toggle.button--inline:hover {
  border-radius: 0;
  color: white;
  display: none;
  font-size: 1.2rem;
  height: 2rem;
  line-height: 1.2rem;
  padding: 0;
  position: relative;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase; }
  .banner__menu-toggle.button--inline span,
  .banner__menu-toggle.button--inline:hover span {
    display: block;
    position: relative;
    top: 0.6rem; }
  .banner__menu-toggle.button--inline svg,
  .banner__menu-toggle.button--inline:hover svg {
    width: 1.6rem;
    display: inline-block;
    margin-left: 0.25rem; }

.banner__menu-toggle.button--inline.banner__menu-toggle--hide,
.banner__menu-toggle.button--inline.banner__menu-toggle--hide:hover {
  position: relative;
  top: -0.1rem;
  font-size: 2rem;
  height: 2.5rem;
  line-height: 1;
  font-weight: 200; }

@media only screen and (max-width: 900px) {
  .banner {
    position: relative;
    height: 3.8rem !important; }
    .banner.banner--show-mobile-sidebar {
      position: fixed; }
  .banner__tagline {
    display: block;
    margin-left: 0;
    font-size: 0.8rem;
    margin-top: 0.2rem; }
  .banner__logo,
  .banner__h1--env {
    display: none; }
  .banner .banner__h1 {
    font-size: 2rem;
    letter-spacing: -0.075rem;
    line-height: 1;
    text-shadow: 0.1rem 0 0.25rem rgba(0, 0, 0, 0.1);
    text-shadow: none; } }

.axis path {
  stroke: #a5a5a5; }

.score-history--makePNG .axis {
  font-size: 1.6rem; }

.axis-label {
  font-size: 1.4rem;
  font-weight: 700; }

.axis-label--small {
  font-size: 1rem;
  font-weight: 300; }

.tick line {
  stroke: #a5a5a5; }

.tick text {
  fill: #3a393a; }

.bar rect {
  fill: #a5a5a5; }

.bar--primary rect {
  fill: #0074d9; }

.bar--secondary rect {
  fill: #2b802b; }

.bar text {
  fill: #fff;
  font: 1rem "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.area {
  fill: #d9edff; }

.area--secondary-lighter {
  fill: rgba(43, 128, 43, 0.5); }

.area--cancel-lighter {
  fill: rgba(221, 6, 6, 0.5); }

.area--cancel-light {
  fill: rgba(221, 6, 6, 0.8); }

.area--cancel {
  fill: #dd0606; }

.line {
  fill: none; }

.line--neutral {
  stroke: #a5a5a5; }

.line--primary {
  stroke: #0074d9; }

.line--secondary {
  stroke: #2b802b; }

.line--dotted {
  stroke-dasharray: 1, 1; }

.line--hovered {
  pointer-events: none; }

.legend--horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.5rem; }
  .legend--horizontal *:not(:last-child) {
    margin-right: 1rem; }

.legend-item {
  font-size: 1.2rem;
  display: flex;
  line-height: 1;
  margin-bottom: 0.5rem; }
  .legend-item:before {
    content: "";
    display: block;
    height: 1.2rem;
    width: 1.2rem;
    background: #2b802b;
    margin-right: 0.5rem; }

.legend-item--line:before {
  content: "";
  display: block;
  height: 0.2rem;
  width: 1.2rem;
  position: relative;
  top: 0.5rem; }

.legend-item--primary:before {
  background: #0074d9; }

.legend-item--secondary-lighter:before {
  background: rgba(43, 128, 43, 0.5); }

.legend-item--border-secondary:before {
  border: 1px solid #2b802b; }

.bet-history {
  overflow: visible;
  max-height: 80vh; }

.bet-history__outer {
  position: relative;
  margin-bottom: 4rem; }

.bet-history__tooltip {
  background: #0074d9;
  color: white;
  display: block;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-style: italic;
  padding: 0.5rem 1rem;
  pointer-events: none;
  position: absolute;
  left: 6rem;
  text-align: left;
  top: calc(100% - 6.6rem);
  width: calc(100% - 6rem); }
  .bet-history__tooltip--loser {
    background: #dd0606; }
  .bet-history__tooltip.hide {
    display: none; }
  .bet-history__tooltip--bottom {
    top: auto;
    bottom: 4rem; }

.history__outer {
  position: relative; }

.history__tooltip {
  padding: 0.3rem 0.5rem;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  right: 0.5rem;
  border: 1px solid #a5a5a5;
  text-align: right;
  pointer-events: none;
  max-width: 75%;
  text-shadow: 1px 1px 0 white; }
  .history__tooltip.hide {
    display: none; }
  .history__tooltip:hover {
    cursor: default; }

.history {
  overflow: visible; }

.score-history--makePNG .legend {
  margin-bottom: 1rem; }

.score-history--makePNG .legend-item {
  font-size: 2.4rem;
  margin-right: 4rem;
  font-weight: 700; }
  .score-history--makePNG .legend-item:before {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem; }

.score-history--makePNG .legend-item--line:before {
  height: 0.2rem;
  top: 0.9rem; }

.chart-label--svg {
  font-size: 1.2rem;
  fill: #3a393a;
  font-weight: 700;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.annotation-group {
  pointer-events: none; }

.annotation-note {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #3a393a !important;
  fill: #3a393a !important;
  font-size: 1rem;
  text-shadow: 2px 2px 0px white; }

.bet-history .annotation-note-bg {
  fill: white;
  fill-opacity: 0.9; }

.bet-history .annotation-note-label,
.bet-history .annotation-note-title {
  fill: #3a393a !important; }

.histogram__leader {
  pointer-events: all; }

.histogram__leader .connector-dot {
  fill: #0074d9; }

.histogram__leader .annotation-note-label {
  fill: #0074d9;
  pointer-events: all; }

.histogram__leader .annotation-note-label:hover {
  cursor: pointer; }

.contest__table {
  margin-bottom: 4rem; }

.contest__proposition-list {
  column-count: 3;
  column-gap: 2rem;
  list-style: none; }

.contest__proposition-item {
  display: inline-block;
  margin-bottom: 2rem;
  width: 100%; }
  .contest__proposition-item a {
    color: #3a393a;
    text-decoration: none; }

@media only screen and (max-width: 700px) {
  .contest__proposition-list {
    column-count: 2; } }

@media only screen and (max-width: 500px) {
  .contest__proposition-list {
    column-count: 1; } }

footer {
  background-color: #003d73;
  bottom: 0;
  color: white;
  font-size: 0.8rem;
  line-height: 1;
  height: 1.6rem;
  margin-top: 2rem;
  padding: 0.4rem 2rem 0.4rem calc(250px + 4rem);
  position: relative;
  width: 100%;
  z-index: 1; }
  footer a, footer a:hover {
    color: white;
    text-decoration: none; }

@media only screen and (max-width: 900px) {
  footer {
    padding: 0.4rem 2rem; } }

.label--regular {
  font-weight: 300; }

textarea {
  max-width: 62rem;
  margin-bottom: 1.5rem; }

.form__row {
  display: flex;
  width: 100%;
  max-width: 62rem; }

.form__col--50 {
  width: calc(50% - 1rem); }
  .form__col--50:nth-child(2n + 1) {
    margin-right: 2rem; }

@media only screen and (max-width: 600px) {
  .form__row {
    display: block; }
  .form__col--50 {
    width: 100%; }
    .form__col--50:nth-child(2n + 1) {
      margin-right: 0; } }

input[type="submit"]:disabled,
button:disabled,
.prop-card__button:disabled {
  background-color: #ccc;
  border-color: #ccc; }
  input[type="submit"]:disabled:hover,
  button:disabled:hover,
  .prop-card__button:disabled:hover {
    background-color: #ccc;
    border-color: #ccc; }

input[type="submit"],
button,
.prop-card__button {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400; }
  input[type="submit"].login__submit,
  button.login__submit,
  .login__submit.prop-card__button {
    display: block; }
  input[type="submit"].button--clear,
  button.button--clear,
  .button--clear.prop-card__button {
    display: block; }
  input[type="submit"]:focus,
  button:focus,
  .prop-card__button:focus {
    outline: 0; }

.button--inline,
.button--inline:hover {
  background: transparent;
  color: #0074d9;
  display: inline;
  height: auto;
  margin: 0;
  padding: 0;
  font-size: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  text-decoration: underline;
  text-transform: inherit;
  line-height: inherit;
  border: none; }

.button--inline:hover {
  color: #0074d9; }

.button--no-underline {
  text-decoration: none; }

.button--hide {
  opacity: 0;
  cursor: default; }

.button-group > a.button:not(:first-child),
.button-group > button.button:not(:first-child),
.button-group > .button.prop-card__button:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0.1rem; }

.button-group > a.button:not(:last-child),
.button-group > button.button:not(:last-child),
.button-group > .button.prop-card__button:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

@media screen and (max-width: 500px) {
  .button-group {
    margin-bottom: 2rem; }
  .button-group > a.button,
  .button-group > button.button,
  .button-group > .button.prop-card__button {
    width: 100%;
    margin-bottom: 0; }
    .button-group > a.button:not(:first-child),
    .button-group > button.button:not(:first-child),
    .button-group > .button.prop-card__button:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-left: 0;
      margin-top: 0.1rem; }
    .button-group > a.button:not(:last-child),
    .button-group > button.button:not(:last-child),
    .button-group > .button.prop-card__button:not(:last-child) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .button-group > a.button:first-child,
    .button-group > button.button:first-child,
    .button-group > .button.prop-card__button:first-child {
      border-top-right-radius: 0.4rem; }
    .button-group > a.button:last-child,
    .button-group > button.button:last-child,
    .button-group > .button.prop-card__button:last-child {
      border-bottom-left-radius: 0.4rem; } }

.login__error-message {
  color: #dd0606;
  margin: 0 0 0.5rem; }

.sidebar .login__error-message {
  padding: 0; }

.login__submit--error {
  animation-name: shake;
  animation-duration: 0.5s;
  animation-iteration-count: 2; }

.login__forget {
  color: #3a393a;
  font-size: 1rem;
  text-decoration: none; }
  .login__forget:hover {
    color: #0074d9; }

.login__forget--active,
.login__forget--active:hover {
  color: #3a393a;
  cursor: default; }

input[type="submit"] {
  display: block;
  margin-top: 1rem; }

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.1rem, 0, 0); }
  20%,
  80% {
    transform: translate3d(0.2rem, 0, 0); }
  30%,
  50%,
  70% {
    transform: translate3d(-0.4rem, 0, 0); }
  40%,
  60% {
    transform: translate3d(0.4rem, 0, 0); } }

input[type="text"],
input[type="password"],
input[type="email"],
select {
  max-width: 30rem; }

input[type="text"] + .input-helper,
input[type="password"] + .input-helper,
input[type="email"] + .input-helper,
input[type="text"] + .input-error,
input[type="password"] + .input-error,
input[type="email"] + .input-error {
  margin-top: -1rem;
  max-width: 30rem; }

textarea + .input-helper,
textarea + .input-error {
  margin-top: -1.5rem;
  max-width: 62rem; }

.input-helper {
  color: #505050;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 2rem; }

.input-error {
  color: #dd0606;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 1rem; }

input.text-input--error {
  border: 0.2rem solid #dd0606; }

/*
 * Checkbox and radio inputs
 * Borrowed from https://jsfiddle.net/purplebabar/1mx35bxc/7/
 */
[type="checkbox"],
[type="radio"] {
  position: absolute;
  opacity: 0; }

[type="checkbox"] + label,
[type="radio"] + label {
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
  display: inline-block; }

[type="checkbox"] + label:before,
[type="radio"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.2rem;
  width: 1.8rem;
  height: 1.8rem;
  border: 0.1rem solid #a5a5a5; }

[type="radio"] + label:before,
[type="radio"] + label:after {
  border-radius: 50%; }

[type="checkbox"]:checked + label:after,
[type="radio"]:checked + label:after {
  content: "";
  width: 0.8rem;
  height: 0.8rem;
  background: #0074d9;
  position: absolute;
  top: 0.7rem;
  left: 0.5rem; }

[type="radio"]:disabled + label:before,
[type="checkbox"]:disabled + label:before {
  background-color: #a5a5a5; }

[type="radio"]:disabled + label,
[type="checkbox"]:disabled + label {
  color: #a5a5a5; }

[type="radio"]:disabled:checked + label:after,
[type="checkbox"]:disabled:checked + label:after {
  background: #40a6ff; }

.new-player-form__h4 {
  margin-top: 4rem;
  bottom: 1rem; }

.new-player__newsletters {
  margin-top: 4rem;
  max-width: 62rem; }

input.new-player__submit {
  margin-top: 4rem; }

.home-welcome h1 {
  font-size: 4rem;
  margin-bottom: 1.5rem; }

.home-welcome p {
  font-size: 2rem;
  line-height: normal; }

.home-welcome a {
  color: white;
  text-decoration: underline; }

.home-welcome--prop-page h1 {
  font-size: 1.6rem; }

.home-welcome--prop-page p {
  font-size: 1.6rem; }

.home-welcome__h1--secondary {
  font-weight: 400; }

.home-welcome__h1 + p {
  margin-top: 0; }

p.home-welcome__login {
  margin-top: -1.5rem; }

a.home-welcome__login-link {
  font-size: 1rem;
  text-decoration: none;
  color: #ccc; }

@media only screen and (max-width: 900px) {
  .home-welcome h1 {
    font-size: 2rem; }
  .home-welcome p {
    font-size: 1.6rem; } }

.leaderboard-intro {
  margin-bottom: 4rem; }

.loan-icon {
  height: 1em;
  margin-right: 0.3rem;
  position: relative;
  top: 0.2rem; }

.wap-icon {
  height: 1em;
  margin-right: 0.3rem;
  position: relative;
  top: 0.2rem; }

.player__wap-icon {
  top: 0;
  margin-right: 0.1rem; }

.leader-notes li {
  list-style: none;
  margin-bottom: 0.5rem;
  padding-left: 0;
  text-indent: 0;
  display: flex;
  line-height: normal; }
  .leader-notes li svg {
    flex-grow: 0;
    flex-shrink: 0; }

.leaders-histogram {
  position: relative;
  margin-bottom: 4rem; }
  .leaders-histogram .legend {
    background: rgba(255, 255, 255, 0.9);
    right: 0.5rem;
    padding: 0.5rem;
    position: absolute;
    top: 0.5rem; }
  .leaders-histogram svg {
    left: 0;
    position: relative;
    top: 0; }

.league-top-section {
  margin-bottom: 5rem; }

.league-list {
  columns: 3; }
  .league-list li.league-list__item {
    display: inline-block;
    list-style: none;
    width: 100%; }
    .league-list li.league-list__item a {
      text-decoration: none; }
  .league-list li.league-list__item--owner {
    font-weight: 700; }

@media only screen and (max-width: 700px) {
  .league-list {
    columns: 2; } }

@media only screen and (max-width: 700px) {
  .league-list {
    columns: 1; } }

.loan-offer__fine-print {
  font-size: 1.2rem; }

.tab-nav {
  display: flex;
  border-bottom: 2px solid #a5a5a5;
  margin-bottom: 2rem; }

.tab-nav__tab {
  flex-grow: 1;
  margin: 0 0.2rem;
  padding: 1rem 2rem 0.5rem;
  text-align: center;
  background: #222;
  text-decoration: none;
  border-radius: 0;
  border: none;
  color: white;
  text-transform: none;
  line-height: 1;
  font-size: 1.6rem;
  letter-spacing: 0;
  height: auto; }
  .tab-nav__tab:hover {
    color: white;
    background: #ee2323; }

.tab-nav__tab--active {
  background: #0074d9;
  color: white; }
  .tab-nav__tab--active:hover {
    background: #0074d9;
    cursor: default; }

@media only screen and (max-width: 800px) {
  .tab-nav {
    flex-direction: column;
    border-bottom: none; }
  .tab-nav__tab:not(:last-child) {
    margin-bottom: 0.1rem; } }

.option {
  display: flex;
  padding: 1rem 0; }

.option__header {
  background: white;
  display: flex;
  margin-top: -0.5rem; }
  .option__header .option__odds,
  .option__header .option__bet-input,
  .option__header .option__main {
    font-weight: 700;
    padding-top: 0;
    text-transform: uppercase; }
  .option__header .option__bet-input {
    padding-left: 2rem;
    width: 18rem; }
  .option__header .option__main {
    padding-left: 2rem; }

@supports (position: sticky) {
  .option__header {
    position: sticky;
    padding-top: 2rem;
    top: calc(3.2rem);
    z-index: 100; } }

.option__bet-input {
  flex-grow: 0;
  flex-shrink: 1; }

.option:not(:last-of-type) {
  border-bottom: 1px solid #a5a5a5; }

.option__odds {
  flex-grow: 0;
  flex-shrink: 0;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 3rem;
  font-weight: 100;
  line-height: 1;
  width: 10rem;
  overflow: visible; }
  .option__odds svg {
    height: 1rem;
    overflow: visible;
    display: inline; }

.option__theoretical .option__odds {
  color: #0074d9;
  font-style: italic; }

.option__main {
  flex-grow: 1;
  line-height: normal;
  order: 3;
  padding-left: 2rem; }

.option__main--single-column {
  padding-left: 0; }

.option__name {
  flex-grow: 1; }
  .option__name svg.trend-icon {
    width: 1.3em;
    height: 1.3em;
    margin-top: -0.3em;
    position: relative;
    top: 0.25em; }

.option__team {
  font-weight: 300;
  font-size: 1.2rem;
  font-style: italic;
  color: #505050; }

.option__existing-bet,
.option__potential-net {
  font-weight: 300;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  color: #999; }

.option__potential-net--theoretical {
  font-style: italic; }

.option__existing-bet + .option__potential-net {
  margin-top: 0; }

.option__existing-bet-list {
  padding: 0;
  list-style: none;
  margin: 0.5rem 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .option__existing-bet-list li {
    border-radius: 0.5rem;
    border: #666 0.1rem solid;
    margin-bottom: 0.25rem;
    padding-left: 0;
    text-indent: 0;
    width: calc(50% - 0.5rem); }

.option__existing-bet-amount {
  background-color: #666;
  color: white;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  text-align: center;
  width: 50%; }

.option__existing-bet-odds {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  text-align: center;
  width: 50%; }

.option__show-all-bets,
.option__show-all-bets:hover {
  color: #0074d9;
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
  height: auto;
  margin: -1rem 0 1rem;
  padding: 0;
  text-align: right;
  text-decoration: none;
  width: 100%; }

.option__checkbox {
  width: 4rem; }

.bet-input {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  order: 2;
  padding-left: 2rem;
  width: 18rem; }
  .bet-input input {
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 3rem;
    font-weight: 700;
    height: 8.1rem;
    margin-bottom: 0;
    text-align: right;
    width: calc(100% - 4.5rem); }
  .bet-input input[type="number"]::-webkit-inner-spin-button,
  .bet-input input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.bet-input__modifiers {
  display: flex;
  flex-direction: column; }

button.bet-input__modifier, .bet-input__modifier.prop-card__button {
  background: #0074d9;
  border: none;
  border-radius: 0;
  color: white;
  cursor: pointer;
  display: table-cell;
  font-size: 10px;
  font-weight: 300;
  height: 1.8rem;
  line-height: 1.5rem;
  margin-left: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0.1rem 0 0 0;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 4rem;
  z-index: 10; }
  button.bet-input__modifier:active, .bet-input__modifier.prop-card__button:active {
    top: 0.1rem; }
  button.bet-input__modifier:focus, .bet-input__modifier.prop-card__button:focus {
    outline: 0; }
  button.bet-input__modifier:hover, .bet-input__modifier.prop-card__button:hover {
    background: #40a6ff; }
  button.bet-input__modifier:disabled, .bet-input__modifier.prop-card__button:disabled {
    background-color: #ccc !important;
    cursor: default; }
  button.bet-input__modifier.bet-input__modifier--clear, .bet-input__modifier.bet-input__modifier--clear.prop-card__button {
    background: #dd0606; }
    button.bet-input__modifier.bet-input__modifier--clear:hover, .bet-input__modifier.bet-input__modifier--clear.prop-card__button:hover {
      background: rgba(221, 6, 6, 0.8); }
  button.bet-input__modifier.bet-input__modifier--bet, .bet-input__modifier.bet-input__modifier--bet.prop-card__button {
    background: #2b802b;
    margin-bottom: 0; }
    button.bet-input__modifier.bet-input__modifier--bet:hover, .bet-input__modifier.bet-input__modifier--bet.prop-card__button:hover {
      background: rgba(43, 128, 43, 0.8); }

.option--winner {
  color: #0074d9; }
  .option--winner svg {
    height: 0.8em; }

@media only screen and (max-width: 900px) {
  .option__header {
    top: 0; }
    .option__header .option__bet-input {
      display: none; }
  @supports not (display: grid) {
    .option {
      display: block; }
      .option .option__odds {
        float: left; }
      .option .bet-input,
      .option .option__main {
        padding-left: 1rem;
        margin-left: 6rem;
        width: calc(100% - 6rem); } }
  .option {
    display: grid;
    grid-template-columns: 5rem auto;
    grid-template-rows: auto;
    grid-template-areas: "odds label" "odds input"; }
  .option__odds {
    grid-area: odds;
    font-size: 1.6rem;
    margin-top: 0;
    margin-bottom: auto;
    width: 5rem;
    line-height: normal; }
  .option__main {
    grid-area: label; }
  .bet-input:before {
    content: "Enter a new bet:";
    display: block;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
    font-weight: 700;
    width: 100%; }
  .bet-input {
    grid-area: input;
    display: block;
    width: 100%; }
  .bet-input input {
    text-align: left;
    height: 5rem;
    width: 100%;
    max-width: none; }
  .bet-input__modifiers {
    flex-direction: row;
    padding-top: 0.5rem; }
  button.bet-input__modifier, .bet-input__modifier.prop-card__button {
    padding: 0.6rem 1rem;
    background: #0074d9;
    border-radius: 0.2rem;
    color: white;
    margin-right: 0.5rem;
    height: auto;
    margin-bottom: 0;
    flex-grow: 1; }
    button.bet-input__modifier:first-child, .bet-input__modifier.prop-card__button:first-child {
      margin-left: 0; }
    button.bet-input__modifier:last-child, .bet-input__modifier.prop-card__button:last-child {
      margin-right: 0; }
  button.bet-input__modifier.bet-input__modifier--clear, .bet-input__modifier.bet-input__modifier--clear.prop-card__button {
    background: #dd0606;
    color: white; } }

.player_details li {
  border-radius: 0.5rem;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1;
  list-style: none;
  margin-bottom: 0;
  margin-right: 1rem;
  padding-left: 0;
  position: relative;
  text-indent: 0; }
  .player_details li .player__pill {
    background: #0074d9;
    border-radius: 1rem;
    color: white;
    display: block;
    padding: 0.4rem 1rem;
    text-decoration: none;
    white-space: nowrap; }
    .player_details li .player__pill button.pill__edit, .player_details li .player__pill .pill__edit.prop-card__button {
      background: none;
      border: 0;
      display: inline-block;
      font-size: 1rem;
      height: 1rem;
      line-height: 0;
      margin: 0 0 0 0.4rem;
      padding: 0;
      position: relative;
      top: -0.4rem;
      vertical-align: text-bottom;
      width: 1rem; }
      .player_details li .player__pill button.pill__edit:hover, .player_details li .player__pill .pill__edit.prop-card__button:hover {
        background: none; }
      .player_details li .player__pill button.pill__edit svg, .player_details li .player__pill .pill__edit.prop-card__button svg {
        height: 1.4rem;
        width: 1.4rem; }
  .player_details li .player__pill {
    background: #2b802b; }
  .player_details li .player__pill--primary-color {
    background: #0074d9; }
  .player_details li a.player__pill {
    background: #0074d9; }
  .player_details li .player__pill--trophy {
    background: white;
    border: 1px solid #0074d9;
    color: #0074d9;
    padding: 0.3rem 1rem 0.3rem 2.5rem;
    position: relative; }
    .player_details li .player__pill--trophy svg {
      position: absolute;
      top: 0.1rem;
      left: 0.5rem; }
  .player_details li a.player__pill--wap {
    background: #fd9d02; }
  .player_details li a:hover {
    background: #40a6ff; }
  .player_details li .player__pill--editing {
    background: none;
    border-bottom: 1px solid #a5a5a5;
    border-radius: 0;
    padding: 0 0 0.2rem 0; }
    .player_details li .player__pill--editing form {
      margin: 0; }
    .player_details li .player__pill--editing input {
      border: none;
      margin: 0;
      padding: 0;
      width: 15rem;
      font-size: 1.4rem;
      height: auto; }
    .player_details li .player__pill--editing input.pill__input--wide {
      width: 25rem; }

button.pill__save, .pill__save.prop-card__button {
  font-size: 0.8rem;
  height: auto;
  line-height: 1;
  margin: 0;
  padding: 0.2rem 0.4rem;
  border-radius: 0.2rem;
  background: none;
  border: 0.1rem solid #2b802b;
  color: #2b802b;
  position: relative;
  top: -0.2rem; }
  button.pill__save:hover, .pill__save.prop-card__button:hover {
    background: #2b802b;
    color: white;
    border: 0.1rem solid #2b802b; }

button.player__name-edit, .player__name-edit.prop-card__button {
  background-color: transparent;
  border: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative; }
  button.player__name-edit:hover, .player__name-edit.prop-card__button:hover, button.player__name-edit:active, .player__name-edit.prop-card__button:active {
    background-color: transparent;
    border: none; }
  button.player__name-edit svg, .player__name-edit.prop-card__button svg {
    width: 2rem;
    height: 2rem; }

.player__name-edit-form {
  border-bottom: 1px solid #a5a5a5;
  padding: 0 0 0.4rem 0;
  position: relative; }
  .player__name-edit-form input {
    border: 0;
    font-size: 3.5rem;
    height: auto;
    margin: 0 0.5rem 0 0;
    max-width: none;
    padding: 0;
    width: calc(100% - 5rem); }
  .player__name-edit-form button.pill__save, .player__name-edit-form .pill__save.prop-card__button {
    background: #2b802b;
    color: white;
    position: absolute;
    right: 0;
    bottom: 0.5rem;
    width: 10rem;
    font-size: 1.5rem; }
    .player__name-edit-form button.pill__save:hover, .player__name-edit-form .pill__save.prop-card__button:hover {
      background: rgba(43, 128, 43, 0.8);
      border: 1px solid rgba(43, 128, 43, 0.8); }

.preference--checkbox {
  margin-bottom: 0.5rem;
  position: relative; }
  .preference--checkbox label {
    z-index: 10;
    opacity: 1;
    position: relative;
    transition: opacity 0.2s ease-in-out;
    transition-delay: 0.5s; }
  .preference--checkbox label.preference-label__change {
    opacity: 0;
    padding-left: 2.5rem;
    position: absolute;
    top: 0;
    z-index: 0; }
  .preference--checkbox.active label {
    opacity: 0;
    z-index: 0; }
  .preference--checkbox.active label.preference-label__change {
    opacity: 1;
    z-index: 10; }

.player__reminders {
  margin-bottom: 2rem; }

@media only screen and (max-width: 600px) {
  .player__name-edit-form {
    border-bottom: 0;
    padding: 0;
    margin-bottom: 2rem; }
    .player__name-edit-form input {
      padding-bottom: 0.4rem;
      border-bottom: 1px solid #a5a5a5;
      width: 100%;
      margin: 0 0 1rem; }
    .player__name-edit-form button, .player__name-edit-form .prop-card__button {
      position: relative;
      display: block;
      width: 100%;
      height: 3rem; } }

#score-history {
  margin-top: 1rem; }

.player__label {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  border-bottom: 1px solid #a5a5a5;
  margin-bottom: 0.5rem; }
  .player__label--center {
    text-align: center; }
  .player__label + * {
    margin-top: 0; }

.player__label--sub {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 0;
  letter-spacing: 0; }

.player__label--result {
  margin: 0 0 0.5rem;
  font-size: 1.2rem;
  border-bottom: 0.1rem solid #a5a5a5; }

.player__stat {
  font-weight: 800;
  font-size: 4rem;
  line-height: 1;
  margin-top: 0; }
  .player__stat .player__stat-memo {
    font-size: 1.2rem;
    font-weight: 300;
    display: block; }

.player__explainer {
  font-size: 1.2rem;
  margin-top: 0;
  line-height: normal; }

.player__list {
  margin-top: 0;
  list-style: none; }

.player__list--ruled li:not(:last-child) {
  border-bottom: 1px dotted #a5a5a5;
  padding-bottom: 0.5rem; }

.player__list-item {
  display: flex;
  font-size: 1.2rem;
  line-height: normal;
  margin-bottom: 0.5rem;
  padding: 0;
  text-indent: 0; }
  .player__list-item span.player__list-item-rank {
    width: 2rem;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.2rem;
    text-align: right; }
  .player__list-item span.player__list-item-text {
    flex-grow: 1; }
  .player__list-item span.player__list-item-figure {
    flex-grow: 1;
    flex-shrink: 0;
    text-align: right;
    width: 4rem; }

.player__stat--points {
  font-size: 8rem; }

.player__winner-loser-item {
  display: flex;
  text-indent: 0;
  padding-left: 0; }

.player__winner-loser-amount {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 2rem;
  text-align: right;
  width: 6rem; }

.player__result-chart {
  display: flex;
  margin-bottom: 1rem; }

.player__result-chart-label {
  font-size: 1.2rem;
  width: 8rem;
  line-height: 1;
  position: relative;
  top: 0.2rem; }

.bar__outer {
  width: 100%;
  height: 1.5rem;
  background-color: #fafafa;
  border-right: 0.1rem solid #0074d9;
  margin-bottom: 0.5rem; }

.bar__outer--secondary {
  border-right: 0.1rem solid #2b802b; }

.bar__inner {
  background-color: #0074d9;
  height: 100%; }

.bar__inner--secondary {
  background-color: #2b802b; }

.player__row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem; }
  .player__row p {
    font-size: 1.2rem;
    line-height: normal; }

.player__row--no-margin-bottom {
  margin-bottom: 0 !important; }

div[class^="player__col--"] *:last-child:not(h2) {
  margin-bottom: 0; }

.player__col--100 {
  width: 100%; }

.player__col--25 {
  width: calc(25% - (4rem * 3 / 4));
  margin-right: 4rem; }
  .player__col--25:nth-child(4n) {
    margin-right: 0; }

.player__col--50 {
  width: calc(50% - (4rem * 1 / 2));
  margin-right: 4rem; }
  .player__col--50:nth-child(2n) {
    margin-right: 0; }

.player-contest {
  margin-bottom: 1rem; }

.player-contest-bar__outer {
  height: 1.5rem;
  margin-bottom: 0.25rem;
  position: relative; }
  .player-contest-bar__outer .player-contest-bar__label {
    position: absolute;
    top: 0.15rem;
    white-space: nowrap;
    width: 45%; }
  .player-contest-bar__outer .player-contest-bar__bar {
    height: 1.5rem;
    position: absolute; }
  .player-contest-bar__outer.player-contest-bar__outer--gain .player-contest-bar__label {
    right: calc(50% + 0.5rem);
    text-align: right; }
    .player-contest-bar__outer.player-contest-bar__outer--gain .player-contest-bar__label::before {
      content: "+"; }
  .player-contest-bar__outer.player-contest-bar__outer--gain .player-contest-bar__bar {
    background-color: #2b802b;
    left: 50%; }
  .player-contest-bar__outer.player-contest-bar__outer--loss .player-contest-bar__label {
    left: calc(50% + 0.25rem); }
  .player-contest-bar__outer.player-contest-bar__outer--loss .player-contest-bar__bar {
    background-color: #dd0606;
    right: 50%; }

.player-contest-bar__outer--roi.player-contest-bar__outer--gain .player-contest-bar__bar {
  background-color: #0074d9; }

@media only screen and (max-width: 700px) {
  .player__col--25 {
    width: calc(50% - (4rem * 1 / 2));
    margin-right: 4rem; }
    .player__col--25:nth-child(4n) {
      margin-right: 4rem; }
    .player__col--25:nth-child(2n) {
      margin-right: 0; }
    .player__col--25:not(:nth-last-child(-n + 2)) {
      margin-bottom: 2rem; } }

@media only screen and (max-width: 400px) {
  .player__row {
    margin-bottom: 2rem; }
  .player__col--25,
  .player__col--50 {
    width: 100%;
    margin-right: 0; }
    .player__col--25:nth-child(4n), .player__col--25:nth-child(2n),
    .player__col--50:nth-child(4n),
    .player__col--50:nth-child(2n) {
      margin-right: 0; }
    .player__col--25:not(:last-child),
    .player__col--50:not(:last-child) {
      margin-bottom: 2rem; }
  .bet-history__outer {
    margin-top: 2rem; } }

.proposition__label {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 1.4rem;
  margin-bottom: 1rem; }
  .proposition__label a {
    text-decoration: none; }

.proposition__content {
  display: flex;
  width: 100%; }

.proposition__form {
  margin-bottom: 0; }

.proposition__meta {
  border-left: 1px solid #a5a5a5;
  flex-basis: 30rem;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 1.2rem;
  line-height: normal;
  margin-left: 2rem;
  max-width: 30rem;
  min-width: 20rem;
  order: 2;
  padding-left: 2rem; }
  .proposition__meta a {
    text-decoration: none; }
    .proposition__meta a:hover {
      color: #0074d9; }
  .proposition__meta h6 {
    border-bottom: 1px solid #a5a5a5;
    font-size: 1rem;
    line-height: normal;
    margin-bottom: 0.3rem;
    margin-top: 1.6rem;
    padding-bottom: 0.3rem;
    text-transform: uppercase; }
  .proposition__meta > h6:first-child {
    margin-top: 0; }
  .proposition__meta p {
    margin-bottom: 0; }
  .proposition__meta p + p {
    margin-top: 1.2rem; }
  .proposition__meta ul {
    margin-bottom: 0; }

.proposition__meta-h6 {
  border-bottom: 1px solid #a5a5a5;
  font-size: 1rem;
  line-height: normal;
  margin-bottom: 0.3rem;
  margin-top: 4rem;
  padding-bottom: 0.3rem;
  text-transform: uppercase; }

.proposition__meta-p--with-icon svg {
  width: 1.6rem;
  height: 1.2rem;
  margin-right: 0.25rem;
  position: relative;
  top: 0.1rem; }

.proposition__meta-row {
  display: flex; }

.proposition__meta-col {
  width: calc(50% - 1rem); }
  .proposition__meta-col:first-child {
    margin-right: 2rem; }

.proposition__related-races-item {
  display: flex;
  list-style: none;
  padding: 0;
  text-indent: 0; }
  .proposition__related-races-item svg {
    height: 1em;
    width: 1em;
    position: relative;
    top: 0.1rem;
    margin-right: 0.3rem;
    flex-grow: 0;
    flex-shrink: 0; }
  .proposition__related-races-item a {
    display: block;
    flex-grow: 1; }
  .proposition__related-races-item:last-child {
    margin-bottom: 0; }

.winner-loser-list {
  list-style: none; }
  .winner-loser-list a {
    text-decoration: none;
    color: #0074d9; }

.proposition__options {
  flex-grow: 1; }

.button--bet {
  font-size: 2rem;
  margin-bottom: 0;
  margin-top: 2rem;
  width: 100%; }

.bet-messages {
  background-color: #0074d9;
  color: white;
  margin: 2rem 0;
  padding: 2rem; }
  .bet-messages li:last-child {
    margin-bottom: 0; }

.bet-message {
  line-height: normal;
  list-style: none;
  display: flex;
  text-indent: 0;
  padding-left: 0; }
  .bet-message span {
    display: block; }
  .bet-message span.bet-message__icon {
    flex-grow: 0;
    flex-shrink: 0;
    height: 1.6rem;
    margin-right: 1rem;
    position: relative;
    top: 0.1rem;
    width: 1.6rem; }
  .bet-message span.bet-message__text {
    flex-grow: 1; }
  .bet-message--error {
    font-weight: 700; }
  .bet-message--suggestion {
    line-height: normal;
    list-style: none;
    margin-top: 2rem; }
    .bet-message--suggestion a {
      color: white;
      text-decoration: none; }
  .bet-message--first-bet {
    display: block;
    font-size: 2rem;
    padding-left: 0;
    text-indent: 0; }
  .bet-message--social-share {
    display: block;
    padding-left: 0;
    text-indent: 0; }
    .bet-message--social-share svg {
      position: relative;
      line-height: 0;
      top: 0.5rem;
      width: 2.2rem;
      height: 2.2rem; }
      .bet-message--social-share svg:hover {
        filter: drop-shadow(0 0 0.1rem white); }
      .bet-message--social-share svg:active {
        top: 0.6rem;
        filter: none; }

.alert--odds-explainer {
  background: #0074d9;
  display: flex;
  font-size: 1.4rem;
  margin-top: 1rem;
  opacity: 0.1;
  transition: 0.2s opacity ease-out; }
  .alert--odds-explainer svg {
    width: 2em;
    height: 2em;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 1rem; }
  .alert--odds-explainer span {
    flex-grow: 1; }
  .alert--odds-explainer.alert-enter-done {
    opacity: 1; }

.scratches__list {
  list-style: none;
  margin-bottom: 0; }

.scratch {
  margin-bottom: 0;
  font-size: 1.4rem; }

.scratch:not(:last-child) {
  margin-bottom: 1rem; }

.scratch__reason {
  display: span;
  font-size: 1.2rem;
  font-style: italic; }

@media only screen and (max-width: 1100px) {
  .proposition__content {
    flex-direction: column; }
  .proposition__meta {
    margin-left: 0;
    max-width: none;
    border: none;
    margin-bottom: 2rem;
    order: 1;
    padding-left: 0;
    width: 100%;
    flex-basis: 0; }
  .proposition__options {
    order: 2; }
  .proposition__related {
    display: none; } }

.proposition-menu {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem; }

.proposition-menu__h2 {
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 0.2rem;
  margin: 0 auto 2rem;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 80%; }
  .proposition-menu__h2 a {
    text-decoration: none;
    color: #3a393a; }
    .proposition-menu__h2 a:hover {
      color: #0074d9; }
  .proposition-menu__h2 span {
    background: white;
    padding: 0 1rem;
    display: inline-block;
    position: relative;
    font-size: 1.4rem;
    line-height: 1; }
  .proposition-menu__h2 span:before,
  .proposition-menu__h2 span:after {
    content: "";
    border-top: 1px solid;
    width: 1000px;
    position: absolute;
    border-top: 1px solid #a5a5a5;
    top: 0.45rem; }
  .proposition-menu__h2 span:before {
    right: 100%; }
  .proposition-menu__h2 span:after {
    left: 100%; }
  .proposition-menu__h2:before, .proposition-menu__h2:after {
    content: "";
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    background: #a5a5a5;
    position: absolute;
    top: 0.35rem; }
  .proposition-menu__h2:before {
    left: 0; }
  .proposition-menu__h2:after {
    right: 0; }

.proposition-menu__h2--space-above {
  margin-top: 8rem; }

.proposition-menu__h3 {
  border-bottom: 0.1rem solid #a5a5a5;
  font-size: 1.6rem;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2rem; }

@media only screen and (max-width: 400px) {
  .proposition-menu__h2 {
    width: 100%;
    letter-spacing: 0.1rem; }
  .proposition-menu__h3 {
    letter-spacing: 0.1rem; } }

.prop-card {
  background: white;
  box-shadow: 0.2rem 0 1rem rgba(0, 0, 0, 0.2);
  display: block;
  margin-bottom: 2.5rem;
  padding-bottom: 4rem;
  padding-top: 1rem;
  position: relative;
  text-decoration: none;
  width: calc((1 / 3) * 100% - (4rem / 3));
  padding-bottom: 11rem; }
  .prop-card.prop-card--logged-in {
    padding-bottom: 13rem; }
  .prop-card:not(:nth-child(3n)) {
    margin-right: 2rem; }
  .prop-card:hover {
    background-color: #fafafa; }
    .prop-card:hover .prop-card__race {
      color: #0074d9; }
    .prop-card:hover .prop-card__button {
      animation: none;
      background-color: #0074d9;
      border-color: #0074d9; }
  .prop-card:active:hover {
    top: 0.2rem;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2); }

.proposition-menu__h2--featured {
  max-width: 400px; }

.proposition-menu--featured .prop-card {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 9rem; }

.proposition-menu--featured .prop-card__question {
  font-size: 3rem; }

@media only screen and (max-width: 1000px) {
  .prop-card {
    width: calc(50% - 1rem); }
    .prop-card:not(:nth-child(3n)) {
      margin-right: 0; }
    .prop-card:nth-child(2n + 1) {
      margin-right: 2rem; } }

@media only screen and (max-width: 500px) {
  .proposition-menu {
    display: block; }
  .prop-card {
    width: 100%;
    margin-right: 0; } }

.prop-card__alert {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  background: #0074d9;
  padding: 0.25 0.5;
  position: relative;
  top: -1rem; }

.prop-card__race {
  margin: 0 1rem 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #3a393a; }

.prop-card__question {
  font-size: 2rem;
  margin: 0 1rem 2rem;
  color: #3a393a; }

.prop-card__meta {
  bottom: 4rem;
  color: #505050;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.2rem;
  line-height: normal;
  margin-bottom: -0.2rem;
  padding: 0;
  position: absolute;
  width: 100%; }
  .prop-card__meta strong {
    display: block;
    border-bottom: 0.25rem;
    font-size: 0.8rem;
    line-height: 1.6;
    text-transform: uppercase; }
  .prop-card__meta .prop-card__meta-item {
    width: 50%;
    padding: 0 1rem 1rem; }
  .prop-card__meta .prop-card__meta-item--full-width {
    width: 100%; }
  .prop-card__meta .prop-card__meta-item--alert {
    color: #dd0606; }
    .prop-card__meta .prop-card__meta-item--alert strong {
      color: #505050; }

@media only screen and (min-width: 800px) {
  .proposition-menu--featured .prop-card .prop-card__meta-item {
    width: 25%; }
  .proposition-menu--featured .prop-card:not(.prop-card--logged-in) {
    padding-bottom: 8rem; }
    .proposition-menu--featured .prop-card:not(.prop-card--logged-in) .prop-card__meta-item {
      width: 50%; } }

@media only screen and (max-width: 799px) {
  .proposition-menu--featured .prop-card {
    padding-bottom: 11rem; }
  .proposition-menu--featured .prop-card--logged-in {
    padding-bottom: 13rem; } }

@media only screen and (min-width: 375px) and (max-width: 900px) {
  .prop-card {
    padding-bottom: 9rem; }
  .prop-card--logged-in {
    padding-bottom: 11rem; } }

.prop-card__button {
  border-radius: 0;
  bottom: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  animation: buttonPulse 20s infinite; }
  .prop-card__button:not(.button--inline):active {
    position: absolute;
    bottom: 0;
    top: inherit; }
  .prop-card__button.prop-card__button--inactive {
    animation: none;
    background: #444;
    border: 0.1rem solid #444; }

@keyframes buttonPulse {
  0% {
    background-color: #2b802b; }
  45% {
    background-color: #2b802b; }
  50% {
    background-color: #184718; }
  55% {
    background-color: #2b802b; }
  100% {
    background-color: #2b802b; } }

.proposition-menu__sections {
  display: flex; }

.proposition-menu__section {
  width: calc(50% - 2rem); }
  .proposition-menu__section:first-child {
    margin-right: 4rem; }

.winner-loser {
  flex-wrap: wrap; }

.winner-loser__bet-list {
  list-style: none;
  width: 100%;
  margin: 0.5rem 0 0 2rem; }

.winner-loser__bet-item {
  text-indent: -0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 0.4rem;
  padding-left: 0; }

.winner-loser__bet-item--winner {
  font-weight: 700; }

.winner-loser__show-more,
.winner-loser__show-more:hover {
  text-decoration: none;
  color: #505050; }

@media only screen and (max-width: 600px) {
  .proposition-menu__sections {
    display: block; }
  .proposition-menu__section {
    width: 100%; }
    .proposition-menu__section:first-child {
      margin-right: 0;
      margin-bottom: 4rem; } }

.proposition-menu--grid {
  margin-bottom: 8rem; }

.proposition-menu__list-item {
  list-style: none;
  line-height: normal;
  padding-left: 0;
  text-indent: 0; }
  .proposition-menu__list-item a {
    color: #3a393a;
    text-decoration: none; }

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #a5a5a5;
  border-radius: .4rem;
  max-width: 62rem;
  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;
  /* clicking anywhere will focus the input */
  cursor: text;
  margin-bottom: 0.5rem; }

.react-tags.is-focused {
  border-color: #b1b1b1; }

.react-tags__selected {
  display: inline; }

button.react-tags__selected-tag, .react-tags__selected-tag.prop-card__button {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #a5a5a5;
  border-radius: 2px;
  background: #fafafa;
  color: #3a393a;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit; }

.react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px; }

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #dd0606;
  background: #fafafa; }

.react-tags__selected-tag:hover:after,
.react-tags__selected-tag:focus:after {
  color: #dd0606; }

.react-tags__search {
  display: inline-block;
  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;
  /* prevent autoresize overflowing the container */
  max-width: 100%; }

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative; } }

.react-tags__search input {
  background: transparent;
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-width: 20rem; }

.react-tags__search div {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.alert .react-tags {
  background: white;
  margin-top: -1rem; }
  .alert .react-tags + button, .alert .react-tags + .prop-card__button {
    margin-top: 1rem; }

.react-tags__search input::-ms-clear {
  display: none; }

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; }

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px; } }

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); }

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  margin-bottom: 0;
  padding: 6px 8px;
  text-indent: 0;
  color: #3a393a; }

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600; }

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee; }

.react-tags__suggestions li.is-active {
  background: #b7cfe0; }

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto; }

.resolution-card {
  padding-bottom: 0; }
  .resolution-card .prop-card__question {
    font-size: 1.5rem;
    margin-bottom: 1rem; }

.resolution-card .prop-card__meta {
  position: relative;
  bottom: 0; }

.resolution-list {
  list-style: none;
  margin: 0; }

li.resolution-item {
  margin-bottom: 0.5rem; }

.resolution-item__more {
  color: #0074d9;
  text-align: right; }

.resolution-icon svg {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.4rem;
  position: relative;
  top: 0.1rem; }

/* Correction to space the checkbox on Scratch listings, because label size is larger */
.option__scratch-reason-input input,
.option__scratch-reason {
  margin-bottom: 0; }

.sidebar {
  background-color: #fafafa;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  color: #3a393a;
  display: flex;
  flex-direction: column;
  padding-top: calc(4.6rem + 2rem);
  transition: padding 0.25s;
  z-index: 100; }
  .sidebar p {
    padding-left: 2rem;
    padding-right: 2rem; }

.banner--scrolled + .sidebar {
  padding-top: calc(3.2rem + 2rem); }

.sidebar__login-form {
  padding-left: 2rem;
  padding-right: 2rem; }

.sidebar__list {
  margin: 0; }

.sidebar__item {
  list-style: none;
  width: 100%;
  margin: 0;
  padding-left: 0;
  text-indent: 0; }

.sidebar__link,
button.sidebar__link--button,
.sidebar__link--button.prop-card__button {
  background-color: transparent;
  border: none;
  color: #3a393a;
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
  height: auto;
  letter-spacing: 0.1rem;
  line-height: normal;
  padding: 0.4rem 2rem;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  margin: 0; }
  .sidebar__link svg,
  button.sidebar__link--button svg,
  .sidebar__link--button.prop-card__button svg {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0.5rem;
    position: relative;
    top: 0.2rem; }
  .sidebar__link:visited,
  button.sidebar__link--button:visited,
  .sidebar__link--button.prop-card__button:visited {
    color: #3a393a; }
  .sidebar__link:hover,
  button.sidebar__link--button:hover,
  .sidebar__link--button.prop-card__button:hover {
    background-color: rgba(0, 0, 0, 0.1); }

.sidebar__link.sidebar__link--active,
.sidebar__link.sidebar__link--active:hover {
  color: #0074d9; }

.sidebar__link.sidebar__link--active:hover {
  background: transparent;
  cursor: default; }

.sidebar__name {
  margin: 0 2rem;
  line-height: 1; }

.sidebar__points-label {
  font-size: 1rem;
  margin: 0 0 0.25rem;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.1rem;
  text-shadow: none; }

.sidebar__points {
  background: #222;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 7rem;
  line-height: 1;
  margin: 0 2rem 0.5rem;
  padding: 1rem;
  position: relative;
  text-align: center;
  text-shadow: 0 0 0.3em #81b3eb;
  letter-spacing: -0.5rem;
  overflow: hidden; }
  .sidebar__points:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3Cpattern%20id%3D%22a%22%20patternUnits%3D%22userSpaceOnUse%22%20width%3D%225%22%20height%3D%225%22%20patternTransform%3D%22rotate(45)%22%3E%3Cpath%20stroke%3D%22%23000%22%20d%3D%22M1%200v5%22%2F%3E%3C%2Fpattern%3E%3C%2Fdefs%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22url(%23a)%22%2F%3E%3C%2Fsvg%3E");
    content: "";
    opacity: 0.2;
    z-index: 5; }
  .sidebar__points div,
  .sidebar__points span {
    position: relative;
    z-index: 20; }
  .sidebar__points-total {
    font-weight: 700; }
  .sidebar__points.sidebar__points--5-digits .sidebar__points-total {
    font-size: 6rem; }

.sidebar__points--fade-enter.sidebar__points {
  animation: neon 1s ease-in-out infinite alternate;
  animation-iteration-count: 2; }

.sidebar__points--fade-exit,
.sidebar__points--fade-exit--active,
.sidebar__points--fade-exit--done {
  display: none; }

.sidebar__points-meta {
  font-size: 1rem;
  display: flex;
  text-transform: uppercase;
  letter-spacing: normal;
  text-shadow: none;
  color: #ccc;
  z-index: 10;
  margin-top: 0.3rem; }
  .sidebar__points-meta-item {
    width: 50%;
    padding: 0 0.25rem;
    line-height: 1.4; }
  .sidebar__points-meta .sidebar__points-meta-label {
    font-size: 0.9rem;
    display: block; }

.sidebar__divider {
  margin: 1rem 0; }

.terms {
  flex-grow: 1;
  position: relative; }

.terms__ul {
  bottom: 0;
  flex-grow: 1;
  font-size: 1rem;
  list-style: none;
  margin-bottom: 0;
  padding: 1rem 2rem;
  position: absolute; }
  .terms__ul li {
    display: inline-block;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    text-indent: 0; }
  .terms__ul a {
    color: #3a393a;
    text-decoration: none; }

@media only screen and (max-width: 900px) {
  .sidebar__divider {
    margin: 1rem 0; }
  .sidebar__link,
  button.sidebar__link--button,
  .sidebar__link--button.prop-card__button {
    padding: 0.5rem 2rem; }
  .sidebar {
    display: block; }
  .terms__ul {
    position: relative; }
  .sidebar__points {
    font-size: 5rem;
    letter-spacing: 0; }
  .sidebar__divider {
    margin: 0.5rem 0; }
  .sidebar__item--slack {
    display: none; }
  .sidebar__points-meta .sidebar__points-meta-label {
    font-size: 1rem;
    display: inline; } }

@media only screen and (max-width: 900px) and (min-height: 560px) {
  .sidebar__link,
  button.sidebar__link--button,
  .sidebar__link--button.prop-card__button {
    padding: 0.5rem 2rem; } }

@media only screen and (max-width: 900px) and (min-height: 610px) {
  .sidebar__link,
  button.sidebar__link--button,
  .sidebar__link--button.prop-card__button {
    padding: 1rem 2rem; } }

@keyframes neon {
  to {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #0074d9, 0 0 70px #0074d9, 0 0 80px #0074d9, 0 0 100px #0074d9, 0 0 150px #0074d9; }
  from {
    text-shadow: 0 0 0 0 #fff, 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #0074d9, 0 0 35px #0074d9, 0 0 40px #0074d9, 0 0 50px #0074d9, 0 0 75px #0074d9; } }

.leaders th:not(:first-child), .leaders .option__header .option__odds:not(:first-child), .option__header .leaders .option__odds:not(:first-child),
.leaders .option__header .option__bet-input:not(:first-child), .option__header .leaders .option__bet-input:not(:first-child),
.leaders .option__header .option__main:not(:first-child), .option__header .leaders .option__main:not(:first-child),
.leaders td:not(:first-child) {
  width: calc((100% - 20rem) / 6); }

.leaders th svg.icon-sort, .leaders .option__header .option__odds svg.icon-sort, .option__header .leaders .option__odds svg.icon-sort, .leaders .option__header .option__bet-input svg.icon-sort, .option__header .leaders .option__bet-input svg.icon-sort, .leaders .option__header .option__main svg.icon-sort, .option__header .leaders .option__main svg.icon-sort {
  position: relative;
  top: 0.4rem;
  height: 2rem;
  width: 2rem; }

.sticky-table-outer {
  overflow-x: scroll; }

.table--sticky {
  position: relative;
  z-index: 1;
  overflow-x: scroll; }
  .table--sticky td,
  .table--sticky th,
  .table--sticky .option__header .option__odds,
  .option__header .table--sticky .option__odds,
  .table--sticky .option__header .option__bet-input,
  .option__header .table--sticky .option__bet-input,
  .table--sticky .option__header .option__main,
  .option__header .table--sticky .option__main {
    min-width: 10rem; }
  .table--sticky th:first-child, .table--sticky .option__header .option__odds:first-child, .option__header .table--sticky .option__odds:first-child,
  .table--sticky .option__header .option__bet-input:first-child, .option__header .table--sticky .option__bet-input:first-child,
  .table--sticky .option__header .option__main:first-child, .option__header .table--sticky .option__main:first-child,
  .table--sticky td:first-child {
    position: sticky;
    z-index: 5;
    background: linear-gradient(to right, white 95%, rgba(255, 255, 255, 0) 100%);
    width: 20rem;
    padding-right: 1rem;
    top: 0;
    left: 0; }

.table--rank th:first-child, .table--rank .option__header .option__odds:first-child, .option__header .table--rank .option__odds:first-child,
.table--rank .option__header .option__bet-input:first-child, .option__header .table--rank .option__bet-input:first-child,
.table--rank .option__header .option__main:first-child, .option__header .table--rank .option__main:first-child {
  padding-left: 3.5rem; }

.rank-row--self td,
.rank-row--self td:first-child {
  background: #0074d9;
  color: white; }
  .rank-row--self td a,
  .rank-row--self td:first-child a {
    color: white; }
  .rank-row--self td:last-child,
  .rank-row--self td:first-child:last-child {
    padding-right: 0.3rem; }

.rank-cell {
  display: flex; }
  .rank-cell a {
    text-decoration: none; }
  .rank-cell .rank-cell__rank {
    width: 4rem;
    padding-right: 0.5rem;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: right; }
  .rank-cell .rank-cell__name {
    flex-grow: 1; }

.transaction-table.table--sticky td,
.transaction-table.table--sticky th,
.transaction-table.table--sticky .option__header .option__odds,
.option__header .transaction-table.table--sticky .option__odds,
.transaction-table.table--sticky .option__header .option__bet-input,
.option__header .transaction-table.table--sticky .option__bet-input,
.transaction-table.table--sticky .option__header .option__main,
.option__header .transaction-table.table--sticky .option__main {
  width: calc((100% - 30rem) / 3); }

.transaction-table.table--sticky td:first-child,
.transaction-table.table--sticky th:first-child,
.transaction-table.table--sticky .option__header .option__odds:first-child,
.option__header .transaction-table.table--sticky .option__odds:first-child,
.transaction-table.table--sticky .option__header .option__bet-input:first-child,
.option__header .transaction-table.table--sticky .option__bet-input:first-child,
.transaction-table.table--sticky .option__header .option__main:first-child,
.option__header .transaction-table.table--sticky .option__main:first-child {
  width: 40rem; }

.th--sortable:not(.th--active):hover {
  color: #0074d9;
  cursor: pointer; }

.proposition-menu--archive a {
  text-decoration: none; }

.proposition-menu--archive .proposition-grid__prop {
  width: 40%; }

.proposition-menu--archive .proposition-grid__date {
  width: 20%; }

.proposition-menu--archive .proposition-grid__winner {
  width: 40%; }

.proposition-menu--archive-with-action .proposition-grid__prop {
  width: 40%; }

.proposition-menu--archive-with-action .proposition-grid__date {
  width: 15%; }

.proposition-menu--archive-with-action .proposition-grid__winner {
  width: 35%; }

.proposition-menu--archive-with-action .proposition-grid__action {
  width: 10%; }

@media only screen and (max-width: 900px) {
  table {
    font-size: 1.4rem; }
    table td,
    table th,
    table .option__header .option__odds,
    .option__header table .option__odds,
    table .option__header .option__bet-input,
    .option__header table .option__bet-input,
    table .option__header .option__main,
    .option__header table .option__main {
      padding: 0.4rem 0.6rem; }
  .leaders th:not(:first-child), .leaders .option__header .option__odds:not(:first-child), .option__header .leaders .option__odds:not(:first-child),
  .leaders .option__header .option__bet-input:not(:first-child), .option__header .leaders .option__bet-input:not(:first-child),
  .leaders .option__header .option__main:not(:first-child), .option__header .leaders .option__main:not(:first-child),
  .leaders td:not(:first-child) {
    width: calc((100% - 15rem) / 6); }
  .table--sticky th:first-child, .table--sticky .option__header .option__odds:first-child, .option__header .table--sticky .option__odds:first-child,
  .table--sticky .option__header .option__bet-input:first-child, .option__header .table--sticky .option__bet-input:first-child,
  .table--sticky .option__header .option__main:first-child, .option__header .table--sticky .option__main:first-child,
  .table--sticky td:first-child {
    width: 12rem; } }

@media only screen and (max-width: 600px) {
  .transaction-table.table--sticky td,
  .transaction-table.table--sticky th,
  .transaction-table.table--sticky .option__header .option__odds,
  .option__header .transaction-table.table--sticky .option__odds,
  .transaction-table.table--sticky .option__header .option__bet-input,
  .option__header .transaction-table.table--sticky .option__bet-input,
  .transaction-table.table--sticky .option__header .option__main,
  .option__header .transaction-table.table--sticky .option__main {
    width: auto;
    min-width: 0; }
  .transaction-table.table--sticky td:first-child,
  .transaction-table.table--sticky th:first-child,
  .transaction-table.table--sticky .option__header .option__odds:first-child,
  .option__header .transaction-table.table--sticky .option__odds:first-child,
  .transaction-table.table--sticky .option__header .option__bet-input:first-child,
  .option__header .transaction-table.table--sticky .option__bet-input:first-child,
  .transaction-table.table--sticky .option__header .option__main:first-child,
  .option__header .transaction-table.table--sticky .option__main:first-child {
    width: 15rem;
    min-width: 15rem; }
  .table--sticky .proposition-grid__prop {
    width: 15rem;
    min-width: 15rem; }
  .table--sticky .proposition-grid__date,
  .table--sticky .proposition-grid__winner,
  .table--sticky .proposition-grid__action {
    width: auto; } }

.fade-enter {
  opacity: 0.01; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

b,
strong {
  font-weight: bold; }

p {
  margin-top: 0; }
  p svg {
    display: inline-block;
    width: 1em;
    position: relative;
    top: 0.2rem; }

p.paragraph--error {
  color: #dd0606; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  letter-spacing: -0.1rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h1 {
  font-size: 4.6rem;
  line-height: 1.2; }

h2 {
  font-size: 3.6rem;
  line-height: 1.25; }

h3 {
  font-size: 2.8rem;
  line-height: 1.3; }

h4 {
  font-size: 2.2rem;
  letter-spacing: -0.08rem;
  line-height: 1.35; }
  h4.deck {
    font-weight: 300;
    margin-bottom: 2rem;
    margin-top: -1rem; }

h5 {
  font-size: 1.8rem;
  letter-spacing: -0.05rem;
  line-height: 1.5;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4;
  margin-bottom: 0.5rem; }
  h6 + p,
  h6 + ul {
    margin-top: 0; }

th svg, .option__header .option__odds svg, .option__header .option__bet-input svg, .option__header .option__main svg,
td svg,
p svg,
li svg {
  height: 1.6rem;
  width: 1.6rem; }

.link {
  color: #0074d9;
  cursor: pointer; }

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 3rem; }
  h4.deck {
    font-size: 1.8rem; } }

.progress-bar {
  position: relative;
  width: 50%;
  height: 0.5rem;
  overflow-x: hidden;
  margin-bottom: 2rem;
  margin-top: 2rem; }

.progress-bar__line {
  position: absolute;
  opacity: 0.4;
  background: #0074d9;
  width: 100%;
  height: 0.5rem; }

.progress-bar__subline {
  position: absolute;
  background: #0074d9;
  height: 0.5rem; }

.progress-bar__inc {
  animation: progress-bar-increase 2s infinite; }

.progress-bar__dec {
  animation: progress-bar-decrease 2s 0.5s infinite; }

@keyframes progress-bar-increase {
  from {
    left: -5%;
    width: 5%; }
  to {
    left: 130%;
    width: 50%; } }

@keyframes progress-bar-decrease {
  from {
    left: -80%;
    width: 80%; }
  to {
    left: 110%;
    width: 10%; } }

.progress-spinner {
  border: 0.25rem solid #f3f3f3;
  /* Light grey */
  border-top: 0.25rem solid #0074d9;
  /* Blue */
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: spin 2s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.four-oh-four {
  background: url(/gr/404.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 66%;
  overflow: visible;
  height: 0; }

@media only screen and (max-width: 900px) {
  .banner__menu-toggle.button--inline,
  .banner__menu-toggle.button--inline:hover {
    display: flex; }
  .sidebar {
    height: auto;
    position: fixed;
    width: 100%;
    top: 0;
    padding-bottom: 1rem;
    z-index: 100;
    padding-top: calc(2rem + 3.2rem);
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);
    transition: transform 0.25s ease-in; }
    .sidebar.sidebar--hide {
      transform: translate3d(0, -100%, 0);
      box-shadow: none; }
  #root {
    display: block; }
  #root > main {
    left: 0;
    width: 100%;
    top: 0; }
    #root > main.main--show-mobile-sidebar {
      top: 3.8rem; }
    #root > main.main--show-mobile-sidebar:after {
      background: rgba(0, 0, 0, 0.5);
      content: "";
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 15; }
    #root > main > section {
      padding: 2rem;
      position: relative; }
  #root > .alert + main > section {
    padding: 2rem; }
  .alert--full-width-show-mobile-sidebar {
    position: relative;
    top: 3.8rem; }
  .alert--full-width-show-mobile-sidebar:after {
    content: "";
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    pointer-events: none; }
  .alert--full-width {
    padding: 2rem;
    left: 0;
    width: 100%; } }
